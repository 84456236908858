import groupsConfig from '~/config/groups';
import { PROPERTY_TYPES, PROPERTY_TYPE_DEFAULT } from '~/config/property-types.js';
import searchConfig from '~/config/search';

export function useSearch() {
  const runtimeConfig = useRuntimeConfig();
  const envData = runtimeConfig?.public;

  // ### STATIC VALUES
  let destinationInputTimer = null;
  const destinationInputDebounce = 750;
  const searchMaxResultsAllowed = 20;
  const recentSearchesStorageKey = 'hWRecentSearches';

  // ### STORES
  const languageStore = useLanguageStore();
  const searchStore = useSearchStore();
  const routeStore = useRouteStore();
  const testingStore = useTestingStore();

  // ### DATA
  const searchResults = ref([]);
  const wizardActiveStep = ref('destination');
  const wizardIsOpen = ref(false);
  const recentSearchesEnabled = testingStore.hasEnabledFeature('web_recentSearches');
  const lastSearchedTerm = ref('');
  const userRecentSearches = useCookie(recentSearchesStorageKey, {
    default: () => [],
    domain: envData?.COOKIE_DOMAIN,
    path: '/',
    sameSite: 'Strict',
    secure: true,
    watch: true,
  });

  // ### COMPOSABLES
  const { t } = useI18n();
  const { $tracking } = useNuxtApp();
  const dateTime = useDateTime();
  const { isBelowMediumScreen } = useMq();
  const { searchForTerm } = useApiAutoComplete();
  const { getNearestCityByCoordinates } = useApiCities();
  const { getCountryById } = useApiCountries();
  const { getAreaById } = useApiAreas();
  const { getRegionById } = useApiRegions();
  const { useUrl } = useUrls();
  const { useFormatter } = useFormatters();

  // ### COMPUTED
  const getGuestsGroupTypes = computed(() => {
    return groupsConfig.groupTypes.map((gtype) => ({
      ...gtype,
      label: t(gtype.label),
      value: String(gtype.value),
    }));
  });

  const getSearchFormTranslations = computed(() => ({
    tAgeRanges: t('t_RWDAGERANGES'),
    tApplyDates: t('t_FORMINPUTAPPLY'),
    tCheckin: t('t_FORMINPUTCHECKIN'),
    tCheckout: t('t_FORMINPUTCHECKOUT'),
    tClearDates: t('t_FORMINPUTCLEAR'),
    tCurrentLocation: t('t_FORMINPUTCURRENTLOCATION'),
    tDates: t('t_FORMINPUTDATES'),
    tDatesNoCheckInError: t('t_FORMINPUTSELECTCHECKIN'),
    tDatesNoCheckOutError: t('t_FORMINPUTSELECTCHECKOUT'),
    tDestination: t('t_FORMINPUTDESTINATION'),
    tDestinationCurrentLocationError: t('t_FORMERRORNOCURRENTLOCATION'),
    tDestinationEmptyResultsError: t('t_FORMERROREMPTYRESULTS'),
    tDestinationFieldLabel: t('t_FORMINPUTWHEREDOYOUWANTTOGO'),
    tGroupType: t('t_RWDGROUPTYPE'),
    tGuests: t('t_FORMINPUTGUESTS'),
    tGuestsNoAgeRangeError: t('t_NOAGERANGEERROR'),
    tGuestsNoGroupTypeError: t('t_NOGROUPTYPEERROR'),
    tMsgNumNightsSelected: t('t_FORMINFOXNIGHTSSELECTED', { NUMNIGHTS: '##NUMNIGHTS##' }),
    tMsgSelectCheckInDate: t('t_FORMINPUTSELECTCHECKIN'),
    tMsgSelectCheckOutDate: t('t_FORMINPUTSELECTCHECKOUT'),
    tNextButton: t('t_FORMINPUTNEXT'),
    tSearchResultsAria: t('t_SEARCHRESULTSARIA'),
    tSubmitLetsGo: t('t_FORMINPUTLETSGO'),
  }));

  const getUserRecentSearchesForForm = computed(() => {
    if (!recentSearchesEnabled || !userRecentSearches.value?.length) {
      return null;
    }
    return [
      {
        label: t('t_SEARCHFORMRECENTSEARCHES'),
        items: userRecentSearches.value
          .sort((rsA, rsB) => new Date(rsB.createdOn) - new Date(rsA.createdOn))
          .map((rs) => rs.destination),
      },
    ];
  });

  const getSearchFormCommonParams = computed(() => ({
    lang: languageStore.getCurrentLanguage.lang,
    destinationDefault: searchStore.getSearchLocation,
    destinationSearchResults: searchResults.value,
    destinationRecentSearches: getUserRecentSearchesForForm?.value,
    destinationMinCharsForSearch: 2,
    dateCheckIn: searchStore.getSearchCheckIn,
    dateCheckOut: searchStore.getSearchCheckOut,
    maxNightsOfStay: searchConfig.extendedMaxNights,
    guestsQty: searchStore.getSearchNumberOfGuests,
    guestsMax: searchConfig.maxGuests,
    groupThreshold: groupsConfig.minGuests - 1,
    groupTypes: getGuestsGroupTypes.value,
    selectedGroupType: searchStore.getSearchGroupType?.value,
    selectedGroupAges: searchStore.getSearchGroupAge,
    groupAgeRanges: groupsConfig.ageRanges,
    translations: getSearchFormTranslations.value,
  }));

  // ### METHODS
  const getSearchResultIcon = function (type) {
    switch (type) {
      case 'country':
        return 'globe';
      case 'city':
        return 'city';
      case 'citydistricts':
        return 'landmark';
      case 'property':
        return 'hostel';
      default:
        return 'location-pin';
    }
  };

  const handleSearchResults = function (searchResponse) {
    if (searchResponse?.length === 1 && searchResponse[0]?.type === 'no-results') {
      return [];
    }

    return searchResponse?.slice(0, searchMaxResultsAllowed)?.reduce((prevState, result, resultIndex) => {
      const labelContent = !result.label || result?.label.match(/, null/) ? result?.en?.label : result.label;

      if (!labelContent) {
        return prevState;
      }

      let categoryIndex = prevState.findIndex((c) => c.label === result.category);
      if (categoryIndex < 0) {
        prevState.push({
          label: result.category,
          items: [],
        });
        categoryIndex = prevState.length - 1;
      }

      const [label, ...labelSmall] = labelContent?.split(',');

      prevState[categoryIndex].items.push({
        id: result.id,
        type: result.type,
        city: result?.city,
        cityId: result?.cityId,
        label: label.trim(),
        labelSmall: labelSmall.join(',').trim(),
        labelSlug: useFormatter.normalizeUrl(label),
        value: String(result.id),
        icon: getSearchResultIcon(result.type),
        rank: resultIndex + 1,
      });

      return prevState;
    }, []);
  };

  const handleDestinationFieldClicked = function () {
    wizardActiveStep.value = 'destination';
    wizardIsOpen.value = toValue(isBelowMediumScreen?.value);

    if (!searchStore.getIsSearchActivated) {
      $tracking?.onSearchActivated('destination');
      searchStore.setSearchActivated(true);

      if (userRecentSearches.value?.length) {
        $tracking?.onRecentDestinationsShown();
      }
    }
  };

  const handleDestinationSearch = async function (search) {
    lastSearchedTerm.value = search.term;
    clearTimeout(destinationInputTimer);
    destinationInputTimer = setTimeout(async () => {
      const searchResponse = await searchForTerm(search.term);
      searchResults.value = handleSearchResults(searchResponse);
      $tracking?.onAutoCompleteSearchCall(search.term, searchResponse);
    }, destinationInputDebounce);
  };

  const handleDestinationItemSelected = function (item) {
    $tracking.onAutoCompleteSearchSelect(lastSearchedTerm.value, item);
    searchStore.setSearchParam('location', { ...item });
    searchStore.setSearchParam('phrase', `${item?.label}${item.labelSmall ? `, ${item.labelSmall}` : ''}`);
  };

  const handleRecentDestinationItemSelected = function () {
    $tracking?.onRecentDestinationClicked();
  };

  const handleCurrentLocation = async function (position) {
    if (position.error) {
      return;
    }

    const latitude = position?.latitude || 0;
    const longitude = position?.longitude || 0;

    const nearestCity = await getNearestCityByCoordinates(latitude, longitude);

    if (nearestCity?.id) {
      handleDestinationItemSelected({
        id: nearestCity?.id,
        type: 'city',
        label: nearestCity?.name,
        labelSmall: nearestCity?.country,
        value: String(nearestCity?.id),
      });
    }
  };

  const handleDatesFieldClicked = function () {
    wizardActiveStep.value = 'dates';
    wizardIsOpen.value = toValue(isBelowMediumScreen?.value);

    if (!searchStore.getIsSearchActivated) {
      $tracking?.onSearchActivated('check-in date');
      searchStore.setSearchActivated(true);
    }
  };

  const handleGuestsFieldClicked = function () {
    wizardActiveStep.value = 'guests';
    wizardIsOpen.value = toValue(isBelowMediumScreen?.value);

    if (!searchStore.getIsSearchActivated) {
      $tracking?.onSearchActivated('guests');
      searchStore.setSearchActivated(true);
    }
  };

  const handleGuestsNumberChanged = function (numGuests) {
    searchStore.setSearchParam('guests', numGuests);
  };

  const handleGuestsGroupTypeChanged = function (groupType) {
    const groupTypeObject = getGuestsGroupTypes.value.find((gtype) => gtype.value === groupType);
    searchStore.setSearchParam('groupType', groupTypeObject);
  };

  const handleGuestsGroupAgesChanged = function (groupAges) {
    searchStore.setSearchParam('groupAge', groupAges);
  };

  const handleWizardClose = function () {
    wizardIsOpen.value = false;
  };

  const handleWizardOpen = function () {
    wizardActiveStep.value = 'destination';
    wizardIsOpen.value = true;

    if (!searchStore.getIsSearchActivated) {
      $tracking?.onSearchActivated(wizardActiveStep.value);
      searchStore.setSearchActivated(true);
    }
  };

  const handleSaveSearchInStorage = function (params, redirectUrl) {
    const guestsTranscode = params.guests.guestsQty > 1 ? 't_RWDGUESTS' : 't_RWDGUEST';
    const checkinLabel = dateTime.formatToHumanReadableShortDate(params.checkin, languageStore.getCurrentLanguage.iso);
    const checkoutLabel = dateTime.formatToHumanReadableShortDate(
      params.checkout,
      languageStore.getCurrentLanguage.iso,
    );

    params.destination.href = redirectUrl;
    params.destination.subLabel = `${checkinLabel} - ${checkoutLabel} ∙ ${params.guests.guestsQty} ${t(guestsTranscode)}`;
    params.destination.icon ??= 'location-pin';

    /* REMOVE EXPIRED SEARCHES */
    const upToDateRecentSearches = userRecentSearches.value.filter((us) => {
      const dayDiffFromToday = dateTime.getDiffInDays(dateTime.today, us.checkin);
      const dayDiffFromExpiry = dateTime.getDiffInDays(us.createdOn, dateTime.today);
      return dayDiffFromToday >= 0 && dayDiffFromExpiry > searchConfig.recentSearchesExpirationDays;
    });
    if (upToDateRecentSearches?.length) {
      userRecentSearches.value = upToDateRecentSearches;
    }

    const destinationRecentSearchIndex = userRecentSearches.value.findIndex(
      (obj) =>
        obj.destination.label === params.destination.label
        && obj.destination.labelSmall === params.destination.labelSmall,
    );

    const newUserRecentSearchEntry = {
      ...params,
      createdOn: new Date(),
    };

    if (destinationRecentSearchIndex < 0) {
      // REMOVE OLDEST RECENT SEARCH
      if (userRecentSearches.value.length >= searchConfig.maxRecentSearchesShown) {
        userRecentSearches.value.pop();
      }
      userRecentSearches.value.unshift(newUserRecentSearchEntry);
    } else {
      userRecentSearches.value.splice(destinationRecentSearchIndex, 1);
      userRecentSearches.value.unshift(newUserRecentSearchEntry);
    }
  };

  const handleSubmit = async function (params) {
    let redirectUrl = null;
    const trackingData = {};

    const propertyTypeObj
      = PROPERTY_TYPES.find((pt) => t(pt.slug) === routeStore.getPropertyType) || PROPERTY_TYPE_DEFAULT;

    searchStore.setSearchCheckIn(params.checkin);
    searchStore.setSearchCheckOut(params.checkout);

    const extraParams = {
      from: dateTime.formatToFlat(params.checkin),
      to: dateTime.formatToFlat(params.checkout),
      guests: params.guests.guestsQty,
    };

    const dateLeadTime = dateTime.getLeadTime(extraParams.from);
    trackingData.arrival_date = extraParams.from;
    trackingData.departure_date = extraParams.to;
    trackingData.number_nights = dateTime.getDiffInDays(extraParams.from, extraParams.to);
    trackingData.number_guests = extraParams.guests;
    trackingData.property_type = routeStore.getPropertyType;
    trackingData.lead_time = dateLeadTime > 0 ? dateLeadTime : 'zero';
    trackingData.category = propertyTypeObj.key;
    trackingData.search_keywords = params.destination?.labelSlug;

    switch (params.destination.type) {
      case 'continent': {
        const continentData = {
          propertyTypeSlug: t(propertyTypeObj.slug),
          urlFriendlyContinent: params.destination?.labelSlug,
        };
        trackingData.destination_continent = continentData?.urlFriendlyContinent;

        redirectUrl = useUrl.getContinentPageUrl(continentData, {});
        break;
      }
      case 'country': {
        const countryInfo = await getCountryById(propertyTypeObj.key, params.destination?.id);
        const countryData = {
          propertyTypeSlug: t(propertyTypeObj.slug),
          urlFriendlyContinent: countryInfo.urlFriendlyContinent,
          urlFriendlyCountry: countryInfo.urlFriendlyName,
        };
        trackingData.destination_country = countryData?.urlFriendlyCountry;
        trackingData.destination_continent = countryData?.urlFriendlyContinent;
        redirectUrl = useUrl.getCountryPageUrl(countryData, {});
        break;
      }
      case 'state': {
        const areaInfo = await getAreaById(propertyTypeObj.key, params.destination?.id);
        const areaData = {
          propertyTypeSlug: t(propertyTypeObj.slug),
          urlFriendlyContinent: areaInfo.urlFriendlyContinent,
          urlFriendlyCountry: areaInfo.urlFriendlyCountry,
          urlFriendlyArea: areaInfo.urlFriendlyName,
        };
        trackingData.destination_area = areaData?.urlFriendlyArea;
        trackingData.destination_country = areaData?.urlFriendlyCountry;
        trackingData.destination_continent = areaData?.urlFriendlyContinent;
        redirectUrl = useUrl.getAreaPageUrl(areaData, {});
        break;
      }
      case 'region': {
        const regionInfo = await getRegionById(propertyTypeObj.key, params.destination?.id);
        const regionData = {
          propertyTypeSlug: t(propertyTypeObj.slug),
          urlFriendlyContinent: regionInfo?.urlFriendlyContinent,
          urlFriendlyCountry: regionInfo?.urlFriendlyCountry,
          urlFriendlyRegion: regionInfo?.urlFriendlyName,
        };
        trackingData.destination_region = regionData?.urlFriendlyRegion;
        trackingData.destination_country = regionData?.urlFriendlyCountry;
        trackingData.destination_continent = regionData?.urlFriendlyContinent;
        redirectUrl = useUrl.getRegionPageUrl(regionData, {});
        break;
      }
      case 'city': {
        const searchString = [params.destination.label];

        if (params.destination.labelSmall) {
          searchString.push(params.destination.labelSmall);
        }

        const cityLocationData = {
          searchString: searchString.join(', '),
          cityId: params.destination.id,
          cityName: params.destination.label,
          countryName: params.destination.labelSmall,
          dateCheckIn: extraParams.from,
          dateCheckOut: extraParams.to,
          numOfGuests: extraParams.guests,
        };

        trackingData.destination_city = params.destination?.label;
        trackingData.destination_city_id = params.destination?.id?.toString();
        trackingData.destination_country = params.destination?.labelSmall;
        trackingData.auto_sort_order_applied = 'sfab';
        trackingData.page_sort_order_applied = 'sfab';
        trackingData.listing_type = 'list';

        redirectUrl = useUrl.getCityPageUrlDynamic(cityLocationData);
        break;
      }
      case 'citydistricts': {
        const cityDistrictParts = params.destination?.labelSmall.split(',');

        const cityDistrictData = {
          searchString: params.destination.labelSmall,
          cityId: params.destination?.cityId,
          cityName: cityDistrictParts[0].trim(),
          countryName: cityDistrictParts[1].trim(),
          dateCheckIn: extraParams.from,
          dateCheckOut: extraParams.to,
          numOfGuests: extraParams.guests,
        };

        trackingData.destination_district = params.destination.label;
        trackingData.destination_city = cityDistrictParts[0];
        trackingData.destination_city_id = params.destination.cityId?.toString();
        trackingData.destination_country = cityDistrictParts[1];
        trackingData.auto_sort_order_applied = 'sfab';
        trackingData.page_sort_order_applied = 'sfab';
        trackingData.listing_type = 'list';

        redirectUrl = useUrl.getCityPageUrlDynamic(cityDistrictData, { district: params.destination?.id });
        break;
      }
      case 'property': {
        const propertyLocationData = {
          propertyId: params.destination?.id,
          urlFriendlyCity: useFormatter.normalizeUrl(params.destination?.city),
          urlFriendlyProperty: useFormatter.normalizeUrl(params.destination?.label),
        };
        const propertyLocationQuery = {
          from: extraParams.from,
          to: extraParams.to,
          guests: extraParams.guests,
        };

        trackingData.property_name = propertyLocationData?.urlFriendlyProperty;
        trackingData.destination_city = propertyLocationData?.urlFriendlyCity;
        trackingData.product_id = params.destination?.id?.toString();
        trackingData.sku = params.destination?.id?.toString();

        redirectUrl = useUrl.getPropertyPageUrlDynamic(propertyLocationData, propertyLocationQuery);
        break;
      }
      default:
        return;
    }

    if (
      params.guests.guestsQty >= groupsConfig.minGuests - 1
      && ['city', 'citydistricts', 'property'].includes(params.destination.type)
    ) {
      const groupType = params.guests.groupType;
      const groupAges = params.guests.groupAges;

      if (groupType) {
        redirectUrl += `&groupType=${groupType}`;
        trackingData.group_type = groupsConfig.groupTypes.find((gt) => gt.value === Number.parseInt(groupType)).code;
      }

      if (groupAges.length > 0) {
        groupAges.forEach((groupAge) => {
          redirectUrl += `&groupAgeRange=${groupAge}`;
        });
        trackingData.group_age_range = groupAges
          .map((groupAge) => {
            const ageRangeObj = groupsConfig.ageRanges.find((ar) => ar.value === groupAge);
            return ageRangeObj?.label || null;
          })
          .join(',');
      }
    }

    if (routeStore.isPropertyPage) {
      redirectUrl += '&origin=microsite';
    }

    if (redirectUrl) {
      // ### SAVE SEARCH TO STORAGE
      if (testingStore.hasEnabledFeature('web_recentSearches')) {
        handleSaveSearchInStorage(params, redirectUrl);
      }

      $tracking?.onSearchSubmitted(trackingData);

      setTimeout(() => {
        navigateTo(redirectUrl, { external: true });
      }, 100);
    }
  };

  return {
    wizardIsOpen,
    wizardActiveStep,
    getGuestsGroupTypes,
    getSearchFormTranslations,
    getSearchFormCommonParams,
    handleSearchResults,
    handleDestinationFieldClicked,
    handleDestinationSearch,
    handleDestinationItemSelected,
    handleRecentDestinationItemSelected,
    handleCurrentLocation,
    handleDatesFieldClicked,
    handleGuestsFieldClicked,
    handleGuestsNumberChanged,
    handleGuestsGroupTypeChanged,
    handleGuestsGroupAgesChanged,
    handleWizardClose,
    handleWizardOpen,
    handleSubmit,
  };
}
