import searchConfig from '~/config/search';

export const useSearchStore = defineStore('searchStore', () => {
  // ### TOP VARIABLES
  const MAX_NUM_NIGHTS = 30;
  let NUMBER_OF_GUESTS = searchConfig.defaultGuests;

  // ### OTHER STORES
  const testingStore = useTestingStore();

  // @TODO: REMOVE EXPERIMENT ONCE IT'S OVER;
  if (testingStore.hasEnabledFeature('web_defaultGuests')) {
    NUMBER_OF_GUESTS = testingStore.getFeatureVariables('web_defaultGuests')?.number_of_guests;
  }

  // ### STATE
  const searchParams = ref({
    location: {},
    checkIn: {},
    checkOut: {},
    disabledUntil: {},
    disabledFrom: {},
    guests: NUMBER_OF_GUESTS,
    groupType: {},
    groupAge: [],
    phrase: '',
  });
  const isSearchActivated = ref(false);

  // ### GETTERS
  const getSearchLocation = computed(() => {
    return searchParams.value.location;
  });
  const getSearchPhrase = computed(() => {
    return searchParams.value.phrase;
  });
  const getSearchCheckIn = computed(() => {
    return searchParams.value.checkIn;
  });
  const getSearchCheckOut = computed(() => {
    return searchParams.value.checkOut;
  });
  const getSearchDisabledUntil = computed(() => {
    return searchParams.value.disabledUntil;
  });
  const getSearchDisableFrom = computed(() => {
    return searchParams.value.disabledFrom;
  });
  const getSearchNumberOfGuests = computed(() => {
    return searchParams.value.guests;
  });
  const getSearchGroupType = computed(() => {
    return searchParams.value.groupType;
  });
  const getSearchGroupAge = computed(() => {
    return searchParams.value.groupAge;
  });
  const getIsSearchActivated = computed(() => {
    return isSearchActivated.value;
  });

  // ### ACTIONS
  function setSearchParam(key, value) {
    if (key === 'checkIn') {
      setSearchCheckIn(value);
      return;
    }

    if (Object.keys(searchParams.value).includes(key)) {
      searchParams.value[key] = value;
    }
  }

  function setSearchCheckIn(value) {
    searchParams.value.checkIn = value;

    const start = new Date(value.year, value.month - 1, value.day);
    const disabledUntil = new Date(new Date(start).setDate(start.getDate() + 1));
    const disableFrom = new Date(start.getFullYear(), start.getMonth(), start.getDate() + MAX_NUM_NIGHTS);

    searchParams.value.disabledUntil = {
      year: disabledUntil.getFullYear(),
      month: disabledUntil.getMonth() + 1,
      day: disabledUntil.getDate(),
    };

    searchParams.value.disabledFrom = {
      year: disableFrom.getFullYear(),
      month: disableFrom.getMonth() + 1,
      day: disableFrom.getDate(),
    };
  }

  function setSearchCheckOut(value) {
    searchParams.value.checkOut = value;
  }

  function addSearchGroupAge(value) {
    searchParams.value.groupAge.push(value);
  }

  function setSearchActivated(value) {
    isSearchActivated.value = value;
  }

  function sendSearchActivatedTracking() {
    /** TODO Implement tracking here */
  }

  return {
    searchParams,
    isSearchActivated,
    getSearchLocation,
    getSearchPhrase,
    getSearchCheckIn,
    getSearchCheckOut,
    getSearchDisabledUntil,
    getSearchDisableFrom,
    getSearchNumberOfGuests,
    getSearchGroupType,
    getSearchGroupAge,
    getIsSearchActivated,
    setSearchParam,
    setSearchCheckIn,
    setSearchCheckOut,
    addSearchGroupAge,
    setSearchActivated,
    sendSearchActivatedTracking,
  };
});
