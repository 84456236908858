import { useCurrencyStore } from '~/stores/currency';

export function useApiRegions() {
  const { $api } = useNuxtApp();
  const { locale } = useI18n();
  const currencyStore = useCurrencyStore();

  const userCurrency = currencyStore.getCurrentCurrency;

  const defaultQuery = { currency: userCurrency?.code };

  const defaultHeaders = { 'Accept-Language': locale?.value };

  const defaultParams = {};

  const getRegionByName = async (propertyType, countryName, regionName) => {
    let result = ref(null);

    const callKey = `getRegionByName${locale?.value}area${propertyType}${countryName}${regionName}${userCurrency?.code}`;
    const endpoint = `region/${propertyType}/${countryName}/${regionName}/`;

    result = ref(await $api.spapi(callKey, endpoint, defaultHeaders, defaultQuery, defaultParams));
    return result?.value?.data || null;
  };

  const getRegionById = async (propertyType, regionId) => {
    let result = ref(null);

    const callKey = `getRegionById${locale?.value}area${propertyType}${regionId}${userCurrency?.code}`;
    const endpoint = `region/${propertyType}/${regionId}/`;

    result = ref(await $api.spapi(callKey, endpoint, defaultHeaders, defaultQuery, defaultParams));
    return result?.value?.data || null;
  };

  return {
    getRegionByName,
    getRegionById,
  };
}
