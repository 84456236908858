export default {
  defaultGuests: 2,
  defaultNights: 3,
  initialNights: 3,
  maxGuests: 80,
  maxNights: 14,
  extendedMaxNights: 30,
  propertiesPerPage: 30,
  imagesPerProperty: 30,
  limitPropertiesForSocialCues: 30,
  maxRecentSearchesShown: 5,
  recentSearchesExpirationDays: 30,
};
